import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import BasePage from './BasePage';

const ContentfulPage = ({ data, pageContext }) => {
  const contentfulPageData = useMemo(
    () => (data?.contentfulPage ? { ...data.contentfulPage } : {}),
    []
  );

  // TODO: Remove this body override once ContentfulPage passes references on its own again.
  const body = useMemo(
    () => ({
      ...contentfulPageData.body,
      ...pageContext.body,
      references: [
        ...(contentfulPageData.body?.references ?? []),
        ...(pageContext.body?.references ?? []),
        ...(data?.allContentfulPageSection?.nodes ?? []),
        ...(data?.allContentfulLinkableArea?.nodes ?? []),
        ...(data?.allContentfulPrintLink?.nodes ?? []),
      ],
    }),
    []
  );

  return <BasePage pageContext={{ ...contentfulPageData, ...pageContext, body }} />;
};

// TODO: Remove allContentfulPageSection & allContentfulLinkableArea once ContentfulPage passes references on its own
// again.
export const query = graphql`
  query ($slug: String!, $language: String!) {
    contentfulPage(node_locale: { eq: $language }, slug: { eq: $slug }) {
      title
      slug
      parentPage {
        title
        slug
        parentPage {
          title
          slug
          parentPage {
            title
            slug
          }
        }
      }
      relatedArticles {
        ... on ContentfulPage {
          title
          slug
          parentPage {
            title
            slug
            parentPage {
              title
              slug
              parentPage {
                title
                slug
              }
            }
          }
        }
      }
      body {
        raw
      }
    }
    allContentfulPageSection(filter: { node_locale: { eq: $language } }) {
      nodes {
        __typename
        color
        contentful_id
        body {
          raw
        }
      }
    }
    allContentfulLinkableArea {
      nodes {
        __typename
        contentful_id
        anchor
      }
    }
    allContentfulPdfLink {
      nodes {
        __typename
        contentful_id
        text
      }
    }
  }
`;

export default ContentfulPage;
